<template>
  <div class="contact-page padding-left-layout padding-top-layout padding-right-layout">
    <page-title :title="textTitle" />
    <div v-if="linkToPage === 'index'" class="form-data">
      <div class="page-header">
        <span>
          {{ $t("contact.description_please_use") }}
        </span>
        <span>
          {{ $t("contact.description_contact_with_you") }}
        </span>
      </div>
      <div class="contact-wrapper">
        <div class="contact-form-type">
          <div class="header">
            <span>{{ $t("contact.label_inquiry_type") }}</span>
            <div class="required-text">
              <span>{{ $t("contact.label_required") }}</span>
            </div>
          </div>
          <default-pull-down-menu
            v-model="contactForm.selectedContactType"
            :items="contactType"
            :initialValue="contactForm.selectedContactType"
            :placeHolderText="typePlaceholder"
            width="100%"
            class="contact-pulldown"
            :class="{ 'error-pulldown': isFormError && contactForm.selectedContactType === '' }"
          />
          <div v-if="isFormError && contactForm.selectedContactType === ''" class="error-message">{{ defaultErrorMessage }}</div>
        </div>
        <div class="contact-form-input">
          <div class="header">
            <span>{{ $t("contact.label_content_inquiry") }}</span>
            <div class="required-text">
              <span>{{ $t("contact.label_required") }}</span>
            </div>
          </div>
          <div class="form-input">
            <textarea
              v-model="contactForm.contactData"
              name="form-input"
              cols="30"
              rows="10"
              :class="{ 'error-textarea': isFormError && contactForm.contactData === '' }"
            >
            </textarea>
          </div>
          <div v-if="isFormError && contactForm.contactData === ''" class="error-message">{{ defaultErrorMessage }}</div>
        </div>
        <div v-if="isCheckLocale !== 'vi'" class="contact-form-checkbox">
          <div class="checkbox-container">
            <div @click="contactForm.isCheck = !contactForm.isCheck" class="checkbox">
              <img v-if="contactForm.isCheck" src="@/assets/icons/checkbox.svg" alt="" class="checkmark">
            </div>
          </div>
          <a href="/others/privacy" class="link-to-page" target="_blank">
            <p class="move-new-page mb-0">{{ $t("contact.hyperlink_privacy_policy") }}<span class="underline"></span></p>
            <img src="@/assets/icons/linkToNewPage.svg" alt="" />
          </a>
          <div class="agree-text">{{ $t("contact.label_agree_to") }}</div>
        </div>
        <div v-if="isCheckLocale === 'vi'" class="contact-form-checkbox">
          <div class="checkbox-container">
            <div @click="contactForm.isCheck = !contactForm.isCheck" class="checkbox">
              <img v-if="contactForm.isCheck" src="@/assets/icons/checkbox.svg" alt="" class="checkmark">
            </div>
          </div>
          <div class="agree-text agree-text-vi">{{ $t("contact.label_agree_to") }}</div>
          <a href="/others/privacy" class="link-to-page" target="_blank">
            <p class="move-new-page mb-0">{{ $t("contact.hyperlink_privacy_policy") }}<span class="underline"></span></p>
            <img src="@/assets/icons/linkToNewPage.svg" alt="" />
          </a>
        </div>
        <div class="contact-submit-btn" @click="validateFormData" :class="!contactForm.isCheck && 'disabled'">
          <span>{{ $t("contact.button_check_your_entries") }} </span>
        </div>
      </div>
    </div>
    <confirm-page
      v-if="linkToPage === 'confirm'"
      :link-to-page.sync="linkToPage"
    />
    <success-page
      v-if="linkToPage === 'success'"
      :link-to-page.sync="linkToPage"
      :contact-form.sync="contactForm"
    />
  </div>
</template>
<script>
import { mapActions, mapState } from 'vuex';
import { ROUTES } from '@/router/constants';
import PageTitle from '@/components/pageTitle/pageTitle.vue';
import DefaultPullDownMenu from '@/components/pulldown/DefaultPullDownMenu';
import ConfirmPage from '@/views/contact/confirm.vue';
import SuccessPage from '@/views/contact/success.vue';
import i18n from '@/lang/i18n';
export default {
  components: { PageTitle, DefaultPullDownMenu, ConfirmPage, SuccessPage },
  data() {
    return {
      breadcrum: [
        {
          text: this.$t("contact.hyperlink_home"),
          disabled: false,
          href: ROUTES.HOME_DASHBOARD,
        },
        {
          text: this.$t("contact.label_contact_us"),
          disabled: true,
          href: ROUTES.CONTACT,
        },
      ],
      contactType: [],
      isFormError: false,
      contactForm: {
        selectedContactType: '',
        isCheck: false,
        contactData: '',
        isConfirmPage: false,
      },
      linkToPage: 'index',
      defaultErrorMessage: this.$t("contact.error_message_error"),
      hoverPolicy: false,
      isCheckLocale: i18n.locale
    }
  },
  mounted() {
    this.updateBreadCrumb(this.breadcrum);
    const message = i18n.messages.ja
    this.contactType = [
      {
        name: this.$t("contact.option_registration_emission_data"),
        value: this.$t("contact.option_registration_emission_data"),
        value_ja: message.contact.option_registration_emission_data
      },
      {
        name: this.$t("contact.option_about_db_customize"),
        value: this.$t("contact.option_about_db_customize"),
        value_ja: message.contact.option_about_db_customize
      },
      {
        name: this.$t("contact.option_about_emission_calc_result"),
        value: this.$t("contact.option_about_emission_calc_result"),
        value_ja: message.contact.option_about_emission_calc_result
      },
      {
        name: this.$t("contact.option_report_output_calc_result"),
        value: this.$t("contact.option_report_output_calc_result"),
        value_ja: message.contact.option_report_output_calc_result
      },
      {
        name: this.$t("contact.option_about_offset_trading"),
        value: this.$t("contact.option_about_offset_trading"),
        value_ja: message.contact.option_about_offset_trading
      },
      {
        name: this.$t("contact.option_other_question"),
        value: this.$t("contact.option_other_question"),
        value_ja: message.contact.option_other_question
      },
      {
        name:this.$t("contact.option_about_product"),
        value:this.$t("contact.option_about_product"),
        value_ja: message.contact.option_about_product
      },
      {
        name: this.$t("contact.option_gx_leader"),
        value: this.$t("contact.option_gx_leader"),
        value_ja: message.contact.option_gx_leader
      },
      {
        name: this.$t("contact.option_new_user"),
        value: this.$t("contact.option_new_user"),
        value_ja: message.contact.option_new_user
      },
      {
        name: this.$t("contact.option_other"),
        value: this.$t("contact.option_other"),
        value_ja: message.contact.option_other
      }
    ]
    if (this.$route.query?.banner && this.$route.query?.banner === "true") {
      this.contactForm.selectedContactType = this.$t("contact.option_gx_leader");
      const newQuery = {...this.$route.query};
      delete newQuery.banner;
      this.$router.push({query: newQuery});
    }

    if(this.$route.query?.is_block_add_user === "true"){
      this.contactForm.selectedContactType = this.$t("contact.option_new_user") 
    }

    if(!this.planType?.is_block_add_user) {
      this.contactType = this.contactType.filter(item => item.value_ja !== message.contact.option_new_user)
    }

    if (this.contactFormStore.isConfirmPage) {
      this.contactForm.selectedContactType = this.contactFormStore.selectedContactType;
      this.contactForm.contactData = this.contactFormStore.contactData;
      this.contactForm.isConfirmPage = true;
      this.contactForm.isCheck = true;
      this.linkToPage = 'confirm';
    }
  },
  computed: {
    ...mapState('userData', ['planType']),
    contactFormStore() {
      return this.$store.state.userData.contactForm;
    },
    textTitle() {
      return this.$t("contact.title_contact_us")
    },
    typePlaceholder() {
      return this.$t("contact.pulldown_select_please_select_type")
    },
  },
  watch: {
    linkToPage: {
      handler() {
        if (this.linkToPage === 'index') {
          this.breadcrum = [
            {
              text: this.$t("contact.hyperlink_home"),
              disabled: false,
              href: ROUTES.HOME_DASHBOARD,
            },
            {
              text: this.$t("contact.label_contact_us"),
              disabled: true,
              href: ROUTES.CONTACT,
            },
          ];
        } else if (this.linkToPage === 'confirm') {
          this.breadcrum = [
            {
              text: this.$t("contact.hyperlink_home"),
              disabled: false,
              href: ROUTES.HOME_DASHBOARD,
            },
            {
              text: this.$t("contact.label_contact_us"),
              disabled: true,
              href: ROUTES.CONTACT,
            },
            {
              text: this.$t("contact.label_confirm_input"),
              disabled: true,
              href: ROUTES.CONTACT,
            }
          ];
        } else {
          this.breadcrum = [
            {
              text: this.$t("contact.hyperlink_home"),
              disabled: false,
              href: ROUTES.HOME_DASHBOARD,
            },
            {
              text: this.$t("contact.label_contact_us"),
              disabled: true,
              href: ROUTES.CONTACT,
            },
            {
              text: this.$t("contact.label_completion") ,
              disabled: true,
              href: ROUTES.CONTACT,
            }
          ];
        }
        this.updateBreadCrumb(this.breadcrum);
        window.scrollTo({ top: 0, behavior: 'smooth' });
      }
    },
    planType(newVal) {
      const message = i18n.messages.ja

      if(!newVal?.is_block_add_user) {
        this.contactType = this.contactType.filter(item => item.value_ja !== message.contact.option_new_user)
      } else {
        this.contactType = this.contactType = [
          {
            name: this.$t("contact.option_registration_emission_data"),
            value: this.$t("contact.option_registration_emission_data"),
            value_ja: message.contact.option_registration_emission_data
          },
          {
            name: this.$t("contact.option_about_db_customize"),
            value: this.$t("contact.option_about_db_customize"),
            value_ja: message.contact.option_about_db_customize
          },
          {
            name: this.$t("contact.option_about_emission_calc_result"),
            value: this.$t("contact.option_about_emission_calc_result"),
            value_ja: message.contact.option_about_emission_calc_result
          },
          {
            name: this.$t("contact.option_report_output_calc_result"),
            value: this.$t("contact.option_report_output_calc_result"),
            value_ja: message.contact.option_report_output_calc_result
          },
          {
            name: this.$t("contact.option_about_offset_trading"),
            value: this.$t("contact.option_about_offset_trading"),
            value_ja: message.contact.option_about_offset_trading
          },
          {
            name: this.$t("contact.option_other_question"),
            value: this.$t("contact.option_other_question"),
            value_ja: message.contact.option_other_question
          },
          {
            name:this.$t("contact.option_about_product"),
            value:this.$t("contact.option_about_product"),
            value_ja: message.contact.option_about_product
          },
          {
            name: this.$t("contact.option_gx_leader"),
            value: this.$t("contact.option_gx_leader"),
            value_ja: message.contact.option_gx_leader
          },
          {
            name: this.$t("contact.option_new_user"),
            value: this.$t("contact.option_new_user"),
            value_ja: message.contact.option_new_user
          },
          {
            name: this.$t("contact.option_other"),
            value: this.$t("contact.option_other"),
            value_ja: message.contact.option_other
          }
        ]
      }
    }
  },
  methods: {
    ...mapActions('commonApp', ['updateBreadCrumb']),
    ...mapActions('userData', ['actionupdateContactForm']),

    validateFormData() {
      if (this.contactForm.isCheck) {
        if (this.contactForm.selectedContactType !== '' && this.contactForm.contactData !== '') {
          this.linkToPage = 'confirm';
          this.contactForm.isConfirmPage = true;
          const typeJaVal = this.contactType.find(item => item.value === this.contactForm.selectedContactType)?.value_ja
          this.contactForm.contact_type_jp = typeJaVal
          this.actionupdateContactForm(this.contactForm);
        } else {
          this.isFormError = true;
        }
      }
    },
  },
}
</script>
<style lang="scss" scoped>
@import './styles/index.scss';
</style>
<style lang="scss">
.contact-pulldown {
  .selection-box-bridge {
    height: 40px;
    padding-bottom: unset !important;
    .input-box {
      line-height: 24px;
    }
  }
  &.error-pulldown {
    border: 2px solid $redMid;
  }
  &.active {
    border: 2px solid $blueMid !important;
  }
  .selection-pulldown {
    margin-top: 4px;
  }
}
</style>
